import styled from "styled-components"

import { MDivider } from "src/ui/Divider/Divider"
import {
  MCardInteractive,
  TCardInteractiveProps,
} from "src/ui/MCard/MCardInteractive"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type ReportCardProps = {
  title: string
  titleComponents?: React.ReactNode[]
  description?: React.ReactNode
  content?: React.ReactNode
  footer?: React.ReactNode
  icon?: React.ReactNode
} & Pick<TCardInteractiveProps, "linkTo" | "onClick" | "padding">

export function ReportCard({
  title,
  titleComponents = [],
  description,
  content,
  footer,
  linkTo,
  onClick,
  padding = "m",
  icon,
}: ReportCardProps) {
  return (
    <MCardInteractive padding={padding} linkTo={linkTo} onClick={onClick}>
      {icon && <Icon>{icon}</Icon>}
      <CardTitle>
        <MText variant="heading3">{title}</MText>

        {(titleComponents?.length ?? 0) > 0 && titleComponents?.map((c) => c)}
      </CardTitle>
      {description && <MText color="secondary">{description}</MText>}

      {content && <ContentBox> {content}</ContentBox>}

      {footer && (
        <div>
          <ReportsDivider />
          {footer}
        </div>
      )}
    </MCardInteractive>
  )
}

const Icon = styled.div`
  padding-bottom: ${spacing.XS};
`

const CardTitle = styled.div`
  display: flex;
  gap: ${spacing.XS};
  padding-bottom: ${spacing.XS};
`

const ReportsDivider = styled(MDivider)`
  margin: ${spacing.L} 0;
`

const ContentBox = styled.div`
  margin-top: ${spacing.L};
`
