import styled from "styled-components"

import { MoldInsightsReportCard } from "src/components/Reports/MoldInsightsReportCard"
import { NoiseIncidentReportCard } from "src/components/Reports/NoiseIncidentReportCard"
import { NoiseSummaryReportCard } from "src/components/Reports/NoiseSummaryReportCard"
import { ReportsFeedbackBox } from "src/components/Reports/ReportsFeedbackBox"
import { breakpoint } from "src/constants/breakpoints"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { usePermissions } from "src/data/user/logic/usePermissions"
import { useTranslate } from "src/i18n/useTranslate"
import { Container } from "src/ui/Container/Container"
import { MainView } from "src/ui/Layout/MainView"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

interface TReportCardSection {
  label: string
  cards: JSX.Element[]
}

export function ReportsView() {
  const { t, langKeys } = useTranslate()
  const { available: reportsAvailable } = useFeatureAvailability({
    feature: "reports",
  })

  const permissions = usePermissions()

  const title = t(langKeys.reports_title)
  const description = t(langKeys.reports_subtitle)

  if (!reportsAvailable || !permissions.reports) return null

  const reportCards: TReportCardSection[] = [
    {
      label: t(langKeys.reports_analytics),
      cards: [
        <NoiseSummaryReportCard
          key={"noise-summary"}
          reportsAvailable={reportsAvailable}
        />,
        <MoldInsightsReportCard key={"indoor-climate"} />,
      ],
    },

    {
      label: t(langKeys.reports_incidents),
      cards: [<NoiseIncidentReportCard key={"incident-report"} />],
    },
  ]

  return (
    <MainView title={title} titleBarProps={{ description }}>
      <StyledContainer>
        <ReportsContainer>
          {reportCards.map(({ label, cards: components }) => (
            <div key={label}>
              <MText variant="heading2">{label}</MText>
              <ComponentsBox>{components}</ComponentsBox>
            </div>
          ))}
        </ReportsContainer>

        <ReportsFeedbackBox />
      </StyledContainer>
    </MainView>
  )
}

const StyledContainer = styled(Container)`
  display: grid;
  gap: ${spacing.L};
`

const ReportsContainer = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const ComponentsBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
  margin-top: ${spacing.M};
  grid-template-columns: repeat(2, minmax(250px, 1fr));

  @container (${breakpoint.smallDown}) {
    grid-template-columns: 1fr;
  }
`
