import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { CreateNoiseIncidentReportButton } from "src/components/Reports/CreateNoiseIncidentReportButton"
import { IncidentReportDialogContainer } from "src/components/Reports/IncidentReportDialogContainer"
import { ReportCard } from "src/components/Reports/ReportCard"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useTranslate } from "src/i18n/useTranslate"
import { Show } from "src/ui/Show/Show"

export function NoiseIncidentReportCard() {
  const { t, langKeys } = useTranslate()
  const noiseIncidentFeature = useFeatureAvailability({
    feature: "reports_incident",
    refresh: true,
  })

  return (
    <ReportCard
      padding="l"
      title={t(langKeys.noise_incident_report_title)}
      titleComponents={[
        <Show key={"incident_reports"} if={!noiseIncidentFeature.available}>
          <UpgradeIconButton context={"incident_reports"} />
        </Show>,
      ]}
      description={t(langKeys.noise_incident_report_body)}
      content={
        <>
          <CreateNoiseIncidentReportButton size="medium" variant="secondary" />
          <IncidentReportDialogContainer
            context={"reports_page"}
            keepDataOnCancel
          />
        </>
      }
    />
  )
}
